import {LedgerActionTypes} from './constants'



export const ledgerResponseSuccess = (actionType ,data)=>({
    type: LedgerActionTypes.API_RESPONSE_SUCCESS,
    payload: {actionType,data}
})
export const ledgerResponseError = (actionType ,error)=>({
    type: LedgerActionTypes.API_RESPONSE_SUCCESS,
    payload: {actionType,error}
})
export const AddGroupAccount =(accountypeid,description,acctnumber,totalaccts,fromacct,toacct)=>({
    type:LedgerActionTypes.ACCOUNT_GROUP,
    payload:{accountypeid,description,acctnumber,totalaccts,fromacct,toacct}
})
export const AddRecievedCash =(userid,cashacount,amount)=>({
    type:LedgerActionTypes.RECIEVEDCASH,
    payload:{userid,cashacount,amount}
})
export const UpdateGroupAccount =(id,accountypeid,description,acctnumber,totalaccts,fromacct,toacct)=>({
    type:LedgerActionTypes.UPD_ACCOUNT_GROUP,
    payload:{id,accountypeid,description,acctnumber,totalaccts,fromacct,toacct}
})
export const AddDetailAccount =(accountypeid,summaryaccountid,description,acctnumber)=>({
    type:LedgerActionTypes.DETAIL_ACCOUNT,
    payload:{accountypeid,summaryaccountid,description,acctnumber}
})
export const updDetailAccount =(id,accountypeid,summaryaccountid,description,acctnumber)=>({
    type:LedgerActionTypes.UPD_DETAIL_ACCOUNT,
    payload:{id,accountypeid,summaryaccountid,description,acctnumber}
})
export const addACCBalance = (entrydate,rowisedata)=>({
    type:LedgerActionTypes.OPEN_ACC_BALANCE,
    payload:{entrydate,rowisedata}
})
export const resetAccounts = () => ({
    type: LedgerActionTypes.RESET,
    payload: {},
});

export const addCashPayment = (entrydate,cashaccountid,details,totalamount,voucherdetdata) => ({
    type: LedgerActionTypes.Add_CashPayment,
    payload: {
    entrydate,cashaccountid,details,totalamount,voucherdetdata
    },
});

export const updCashPayment = (id,entrydate,cashaccountid,details,totalamount,voucherdetdata) => ({
    type: LedgerActionTypes.Upd_CashPayment,
    payload: {
    id,entrydate,cashaccountid,details,totalamount,voucherdetdata
    },
});
export const addExpenseVoucher = (entrydate,account_type,account_id,details,totalamount,voucherdetdata) => ({
    type: LedgerActionTypes.ADD_EXPENSEVOUCHER,
    payload: {
    entrydate,account_type,account_id,details,totalamount,voucherdetdata
    },
});

export const updExpenseVoucher = (id,entrydate,account_type,account_id,details,totalamount,voucherdetdata) => ({
    type: LedgerActionTypes.UPD_EXPENSEVOUCHER,
    payload: {
    id,entrydate,account_type,account_id,details,totalamount,voucherdetdata
    },
});
export const addBankPayment = (entrydate,bankaccountid,details,totalamount,voucherdetdata) => ({
    type: LedgerActionTypes.ADD_BANKPAYMENT,
    payload: {
    entrydate,bankaccountid,details,totalamount,voucherdetdata
    },
});

export const updBankPayment = (id,entrydate,bankaccountid,details,totalamount,voucherdetdata) => ({
    type: LedgerActionTypes.UPD_BANKPAYMENT,
    payload: {
    id,entrydate,bankaccountid,details,totalamount,voucherdetdata
    },
});

export const addCashReceipt = (entrydate,cashaccountid,details,totalamount,voucherdetdata) => ({
    type: LedgerActionTypes.Add_CashReceipt,
    payload: {
    entrydate,cashaccountid,details,totalamount,voucherdetdata
    },
});
export const addBankReceipt = (entrydate,bankaccountid,details,totalamount,voucherdetdata) => ({
    type: LedgerActionTypes.ADD_BANKRECEIPT,
    payload: {
    entrydate,bankaccountid,details,totalamount,voucherdetdata
    },
});
export const addJournalVoucher = (entrydate,details,debit,credit,voucherdetdata) => ({
    type: LedgerActionTypes.Add_JOURNALVOUCHER,
    payload: {entrydate,details,debit,credit,voucherdetdata},
});
export const updJournalVoucher = (id,entrydate,details,debit,credit,voucherdetdata) => ({
    type: LedgerActionTypes.UPD_JOURNALVOUCHER,
    payload: {id,entrydate,details,debit,credit,voucherdetdata},
});
export const updCashReceipt = (id,entrydate,cashaccountid,details,totalamount,voucherdetdata) => ({
    type: LedgerActionTypes.UPD_CashReceipt,
    payload: {
    id,entrydate,cashaccountid,details,totalamount,voucherdetdata
    },
});
export const updBankReceipt = (id,entrydate,bankaccountid,details,totalamount,voucherdetdata) => ({
    type: LedgerActionTypes.UPD_BANKRECEIPT,
    payload: {
    id,entrydate,bankaccountid,details,totalamount,voucherdetdata
    },
});


