import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import {addBank_Receipts,addExpence_Voucher,updExpence_Voucher,updBank_Receipts,addBank_Payments,updBank_Payments,JV_Api,UPD_JV_Api,updCash_Receipts,addCash_Receipts,updCash_Payments,addCash_Payments,add_GroupAccounts,upd_GroupAccounts,addDetailAcc_API,updDetailAcc_API,add_Received_Cash_API,openAccBalances} from '../../helpers';

import { ledgerResponseSuccess, ledgerResponseError } from './actions';

// constants
import { LedgerActionTypes } from './constants';

type groupaccount_type = {
    payload: {accountypeid: string;description: string;acctnumber: string;totalaccts: string;fromacct: string;toacct: string};
    type: string;
};
type recievedcash_type = {
    payload: {userid: string;cashacount: string;amount: string;}
    type:string;
};
type add_detailaccount_type = {
    payload: {accountypeid:string;summaryaccountid: string;description: string;acctnumber: string;};
    type: string;
};
type upd_detailaccount_type = {
    payload: {accountypeid:string;id:string;summaryaccountid: string;description: string;acctnumber: string;};
    type: string;
};
type upd_groupaccount_type = {
    payload: {id:string;accountypeid: string;description: string;acctnumber: string;totalaccts: string;fromacct: string;toacct: string};
    type: string;
};
type balanceType = {
    payload: {
        entrydate: string;rowisedata: Object;
    };
    type: string;
};

type cashPaymentData = {
    payload: {cashaccountid:string;entrydate:string;details:string;totalamount:string;voucherdetdata:[]};
    type: string;
};
type bankPaymentData = {
    payload: {id:string;bankaccountid:string;entrydate:string;details:string;totalamount:string;voucherdetdata:[]};
    type: string;
};

type updcashPaymentData = {
    payload: {id:string;cashaccountid:string;entrydate:string;details:string;totalamount:string;voucherdetdata:[]};
    type: string;
};
type EV_Type = {
    payload: {id:string;account_type:string;account_id:string;entrydate:string;details:string;totalamount:string;voucherdetdata:[]};
    type: string;
};

type cashReceiptData = {
    payload: {id:string;cashaccountid:string;entrydate:string;details:string;totalamount:string;voucherdetdata:[]};
    type: string;
};
type bankReceiptData = {
    payload: {id:string;bankaccountid:string;entrydate:string;details:string;totalamount:string;voucherdetdata:[]};
    type: string;
};
type journalVoucher_type = {
    payload: {id:string;entrydate:string;details:string;debit:string;credit:string;voucherdetdata:[]};
    type: string;
};

function* add_accounts({ payload: {accountypeid,description,acctnumber,totalaccts,fromacct,toacct}}:groupaccount_type): SagaIterator{
    try {
        const response = yield call(add_GroupAccounts, {accountypeid,description,acctnumber,totalaccts,fromacct,toacct});
        //console.log(response);
        const item = response.data;
        yield put(ledgerResponseSuccess(LedgerActionTypes.ACCOUNT_GROUP , item));
    } catch (error) {
        yield put(ledgerResponseError(LedgerActionTypes.ACCOUNT_GROUP , error));
            }
}
function* add_received({ payload: {userid,cashacount,amount}}:recievedcash_type): SagaIterator{
    try {
        const response = yield call(add_Received_Cash_API, {userid,cashacount,amount});
        const item = response.data;
        yield put(ledgerResponseSuccess(LedgerActionTypes.RECIEVEDCASH , item));
    } catch (error) {
        yield put(ledgerResponseError(LedgerActionTypes.RECIEVEDCASH , error));
            }
}
function* upd_accounts({ payload: {id,accountypeid,description,acctnumber,totalaccts,fromacct,toacct}}:upd_groupaccount_type): SagaIterator{
    try {
        const response = yield call(upd_GroupAccounts, {id,accountypeid,description,acctnumber,totalaccts,fromacct,toacct});
        const item = response.data;
        yield put(ledgerResponseSuccess(LedgerActionTypes.UPD_ACCOUNT_GROUP , item));
    } catch (error) {
        yield put(ledgerResponseError(LedgerActionTypes.UPD_ACCOUNT_GROUP , error));
            }
}
function* add_detail_accounts({ payload: {accountypeid,summaryaccountid,description,acctnumber}}:add_detailaccount_type): SagaIterator{
    try {
        const response = yield call(addDetailAcc_API, {accountypeid,summaryaccountid,description,acctnumber});
        const item = response.data;
        yield put(ledgerResponseSuccess(LedgerActionTypes.DETAIL_ACCOUNT , item));
    } catch (error) {
        yield put(ledgerResponseError(LedgerActionTypes.DETAIL_ACCOUNT , error));
            }
}
function* upd_detail_accounts({ payload: {id,accountypeid,summaryaccountid,description,acctnumber}}:upd_detailaccount_type): SagaIterator{
    try {
        const response = yield call(updDetailAcc_API, {id,accountypeid,summaryaccountid,description,acctnumber});
        const item = response.data;
        yield put(ledgerResponseSuccess(LedgerActionTypes.UPD_DETAIL_ACCOUNT , item));
    } catch (error) {
        yield put(ledgerResponseError(LedgerActionTypes.UPD_DETAIL_ACCOUNT , error));
            }
}
function* addAccBalance ({payload:{entrydate,rowisedata}}:balanceType):  SagaIterator{
    try{
        const response = yield call(openAccBalances,{entrydate,rowisedata})
        const balance = response.data;
        yield put(ledgerResponseSuccess(LedgerActionTypes.OPEN_ACC_BALANCE,balance))
    }catch(error: any) {
        yield put(ledgerResponseError(LedgerActionTypes.OPEN_ACC_BALANCE, error)); 
    }
}


function* add_cashpayment({ payload: {entrydate,cashaccountid,details,totalamount,voucherdetdata} }: cashPaymentData): SagaIterator {
    try {
        const response = yield call(addCash_Payments, {entrydate,cashaccountid,details,totalamount,voucherdetdata});
        const cashpay = response.data;
        yield put(ledgerResponseSuccess(LedgerActionTypes.Add_CashPayment, cashpay));
    } catch (error: any) {
        
        yield put(ledgerResponseError(LedgerActionTypes.Add_CashPayment, error));
    }
}


function* upd_cashpayment({ payload: {id,entrydate,cashaccountid,details,totalamount,voucherdetdata} }: updcashPaymentData): SagaIterator{
    try {
        const response = yield call(updCash_Payments, {id,entrydate,cashaccountid,details,totalamount,voucherdetdata});
        const item = response.data;
        yield put(ledgerResponseSuccess(LedgerActionTypes.Upd_CashPayment , item));
    } catch (error) {
        yield put(ledgerResponseError(LedgerActionTypes.Upd_CashPayment , error));
            }
}
function* add_expencevoucher({ payload: {entrydate,account_type,account_id,details,totalamount,voucherdetdata} }: EV_Type): SagaIterator {
    try {
        const response = yield call(addExpence_Voucher, {entrydate,account_type,account_id,details,totalamount,voucherdetdata});
        const cashpay = response.data;
        yield put(ledgerResponseSuccess(LedgerActionTypes.ADD_EXPENSEVOUCHER, cashpay));
    } catch (error: any) {
        
        yield put(ledgerResponseError(LedgerActionTypes.ADD_EXPENSEVOUCHER, error));
    }
}


function* upd_expencevoucher({ payload: {id,entrydate,account_type,account_id,details,totalamount,voucherdetdata} }: EV_Type): SagaIterator{
    try {
        const response = yield call(updExpence_Voucher, {id,entrydate,account_type,account_id,details,totalamount,voucherdetdata});
        const item = response.data;
        yield put(ledgerResponseSuccess(LedgerActionTypes.UPD_EXPENSEVOUCHER , item));
    } catch (error) {
        yield put(ledgerResponseError(LedgerActionTypes.UPD_EXPENSEVOUCHER , error));
            }
}
function* add_bankpayment({ payload: {entrydate,bankaccountid,details,totalamount,voucherdetdata} }: bankPaymentData): SagaIterator {
    try {
        const response = yield call(addBank_Payments, {entrydate,bankaccountid,details,totalamount,voucherdetdata});
        const cashpay = response.data;
        yield put(ledgerResponseSuccess(LedgerActionTypes.ADD_BANKPAYMENT, cashpay));
    } catch (error: any) {
        
        yield put(ledgerResponseError(LedgerActionTypes.ADD_BANKPAYMENT, error));
    }
}


function* upd_bankpayment({ payload: {id,entrydate,bankaccountid,details,totalamount,voucherdetdata} }: bankPaymentData): SagaIterator{
    try {
        const response = yield call(updBank_Payments, {id,entrydate,bankaccountid,details,totalamount,voucherdetdata});
        const item = response.data;
        yield put(ledgerResponseSuccess(LedgerActionTypes.UPD_BANKPAYMENT , item));
    } catch (error) {
        yield put(ledgerResponseError(LedgerActionTypes.UPD_BANKPAYMENT , error));
            }
}


function* add_cashreceipt({ payload: {entrydate,cashaccountid,details,totalamount,voucherdetdata} }: cashReceiptData): SagaIterator {
    try {
        const response = yield call(addCash_Receipts, {entrydate,cashaccountid,details,totalamount,voucherdetdata});
        const cash_receipt = response.data;
        yield put(ledgerResponseSuccess(LedgerActionTypes.Add_CashReceipt, cash_receipt));
    } catch (error: any) {
        
        yield put(ledgerResponseError(LedgerActionTypes.Add_CashReceipt, error));
    }
}
function* upd_cashreceipt({ payload: {id,entrydate,cashaccountid,details,totalamount,voucherdetdata} }: cashReceiptData): SagaIterator {
    try {
        const response = yield call(updCash_Receipts, {id,entrydate,cashaccountid,details,totalamount,voucherdetdata});
        const cash_receipt = response.data;
        yield put(ledgerResponseSuccess(LedgerActionTypes.UPD_CashReceipt, cash_receipt));
    } catch (error: any) {
        
        yield put(ledgerResponseError(LedgerActionTypes.UPD_CashReceipt, error));
    }
}
function* add_bankreceipt({ payload: {entrydate,bankaccountid,details,totalamount,voucherdetdata} }: bankReceiptData): SagaIterator {
    try {
        const response = yield call(addBank_Receipts, {entrydate,bankaccountid,details,totalamount,voucherdetdata});
        const cash_receipt = response.data;
        yield put(ledgerResponseSuccess(LedgerActionTypes.ADD_BANKRECEIPT, cash_receipt));
    } catch (error: any) {
        yield put(ledgerResponseError(LedgerActionTypes.ADD_BANKRECEIPT, error));
    }
}
function* upd_bankreceipt({ payload: {id,entrydate,bankaccountid,details,totalamount,voucherdetdata} }: bankReceiptData): SagaIterator {
    try {
        const response = yield call(updBank_Receipts, {id,entrydate,bankaccountid,details,totalamount,voucherdetdata});
        const cash_receipt = response.data;
        yield put(ledgerResponseSuccess(LedgerActionTypes.UPD_BANKRECEIPT, cash_receipt));
    } catch (error: any) {
        yield put(ledgerResponseError(LedgerActionTypes.UPD_BANKRECEIPT, error));
    }
}
function* add_journalVoucher({ payload: {entrydate,details,debit,credit,voucherdetdata} }: journalVoucher_type): SagaIterator {
    try {
        const response = yield call(JV_Api, {entrydate,details,debit,credit,voucherdetdata});
        const journal_Voucher = response.data;
        yield put(ledgerResponseSuccess(LedgerActionTypes.Add_JOURNALVOUCHER, journal_Voucher));
    } catch (error: any) {
        
        yield put(ledgerResponseError(LedgerActionTypes.Add_JOURNALVOUCHER, error));
    }
}
function* upd_journalVoucher({ payload: {id,entrydate,details,debit,credit,voucherdetdata} }: journalVoucher_type): SagaIterator {
    try {
        const response = yield call(UPD_JV_Api, {id,entrydate,details,debit,credit,voucherdetdata});
        const journal_Voucher = response.data;
        yield put(ledgerResponseSuccess(LedgerActionTypes.UPD_JOURNALVOUCHER, journal_Voucher));
    } catch (error: any) {
        yield put(ledgerResponseError(LedgerActionTypes.UPD_JOURNALVOUCHER, error));
    }
}

export function* watchAdd(): any{   
   yield takeEvery(LedgerActionTypes.ACCOUNT_GROUP, add_accounts);
}
export function* watchJV_UPD(): any{   
   yield takeEvery(LedgerActionTypes.UPD_JOURNALVOUCHER,upd_journalVoucher );
}
export function* watchJV_ADD(): any{   
   yield takeEvery(LedgerActionTypes.Add_JOURNALVOUCHER, add_journalVoucher);
}
export function* watchUpd(): any{   
   yield takeEvery(LedgerActionTypes.UPD_ACCOUNT_GROUP, upd_accounts);
}
export function* watchRece_add(): any{   
    yield takeEvery(LedgerActionTypes.RECIEVEDCASH,add_received);
}
export function* watchAccBalance(): any{   
   yield takeEvery(LedgerActionTypes.OPEN_ACC_BALANCE, addAccBalance);
}
export function* watchadd_detailacc(): any{   
   yield takeEvery(LedgerActionTypes.DETAIL_ACCOUNT, add_detail_accounts);
}
export function* watchupd_detailacc(): any{   
   yield takeEvery(LedgerActionTypes.UPD_DETAIL_ACCOUNT, upd_detail_accounts);
}

export function* addCash_Payment(): any{   
   yield takeEvery(LedgerActionTypes.Add_CashPayment, add_cashpayment);
}

export function* updCash_Payment(): any{   
   yield takeEvery(LedgerActionTypes.Upd_CashPayment, upd_cashpayment);
}
export function* addEx_Watch(): any{   
   yield takeEvery(LedgerActionTypes.ADD_EXPENSEVOUCHER, add_expencevoucher);
}

export function* updEx_Watch(): any{   
   yield takeEvery(LedgerActionTypes.UPD_EXPENSEVOUCHER, upd_expencevoucher);
}
export function* addBank_Payment(): any{   
   yield takeEvery(LedgerActionTypes.ADD_BANKPAYMENT, add_bankpayment);
}

export function* updBank_Payment(): any{   
   yield takeEvery(LedgerActionTypes.UPD_BANKPAYMENT, upd_bankpayment);
}


export function* addCash_Receipt(): any{   
   yield takeEvery(LedgerActionTypes.Add_CashReceipt, add_cashreceipt);
}

export function* addbank_Receipt(): any{   
   yield takeEvery(LedgerActionTypes.ADD_BANKRECEIPT, add_bankreceipt);
}
export function* updbank_Receipt(): any{   
   yield takeEvery(LedgerActionTypes.UPD_BANKRECEIPT, upd_bankreceipt);
}

export function* updCash_Receipt(): any{   
   yield takeEvery(LedgerActionTypes.UPD_CashReceipt, upd_cashreceipt);
}

function* ledgerSaga() {
    yield all([fork(addBank_Payment),fork(addEx_Watch),fork(updEx_Watch),fork(addbank_Receipt),fork(updbank_Receipt),fork(updBank_Payment),fork(addCash_Receipt),fork(watchJV_UPD),fork(watchJV_ADD),fork(updCash_Receipt),fork(addCash_Payment),fork(updCash_Payment),fork(watchAdd),fork(watchUpd),fork(watchadd_detailacc),fork(watchupd_detailacc),fork(watchAccBalance),fork(watchRece_add)]);
}

export default ledgerSaga;
