// constants
import { ServicesActionTypes } from './constants';

export type ServicesActionType = {
    type:
        | ServicesActionTypes.API_RESPONSE_SUCCESS
        | ServicesActionTypes.API_RESPONSE_ERROR
        | ServicesActionTypes.RESET
        | ServicesActionTypes.ADD_SERVICES
        | ServicesActionTypes.UPD_SERVICES
        | ServicesActionTypes.ADD_QUERY
        | ServicesActionTypes.ADD_TOOLS
        | ServicesActionTypes.ADD_SERVICESORDER
        | ServicesActionTypes.UPD_SERVICESORDER
        | ServicesActionTypes.ADD_SERVICES_PERSON
        | ServicesActionTypes.ADD_TOOLS_EXPENSE
        | ServicesActionTypes.UPD_TOOLS_EXPENSE
        | ServicesActionTypes.UPD_ORDER_STAGE
        | ServicesActionTypes.MANAGE_ORDER_STAGE
    payload: {} | string;
};

type InvoiceData = {
    name: string;
    


};


// common success
export const ServicesApiResponseSuccess = (actionType: string, data: InvoiceData | {}): ServicesActionType => ({
    type: ServicesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const ServicesApiResponseError = (actionType: string, error: string): ServicesActionType => ({
    type: ServicesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});


export const addOrderStage = (name:string,percentage:string): ServicesActionType => ({
    type: ServicesActionTypes.MANAGE_ORDER_STAGE,
    payload: {name,percentage},
});

export const addQuery = (name:string): ServicesActionType => ({
    type: ServicesActionTypes.ADD_QUERY,
    payload: {name},
});
export const addTools = (name:string): ServicesActionType => ({
    type: ServicesActionTypes.ADD_TOOLS,
    payload: {name},
});

export const updToolsExpenseVoucher = (id,entrydate,account_type,account_id,details,totalamount,voucherdetdata,toolid) => ({
    type: ServicesActionTypes.UPD_TOOLS_EXPENSE,
    payload: {
    id,entrydate,account_type,account_id,details,totalamount,voucherdetdata,toolid
    },
});

export const addServicePerson = (name:string): ServicesActionType => ({
    type: ServicesActionTypes.ADD_SERVICES_PERSON,
    payload: {name},
});

export const addToolsExpenseVoucher = (entrydate,account_type,account_id,details,totalamount,voucherdetdata,toolid) => ({
    type: ServicesActionTypes.ADD_TOOLS_EXPENSE,
    payload: {
    entrydate,account_type,account_id,details,totalamount,voucherdetdata,toolid
    },
});

export const addServicesOrder = (entrydate:string,sourceid:string,customerid:string,salestype:string,servicepersonid:string,reference:string,notes:string,discount:string,amount:string,netamount:string,toolid:string,tax:string,taxamount:string ,pickuppostal:string ,pickupaddress:string,dropoffpostal:string,dropoffaddress:string,dropoffdate:string,pickupdate:string,deliverydate:string, orderdetdata:Object): ServicesActionType => ({
    type: ServicesActionTypes.ADD_SERVICESORDER,
    payload: { entrydate, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount,toolid,tax,taxamount,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress,dropoffdate,pickupdate,deliverydate, orderdetdata},
});

export const updServicesOrder = (id:string,entrydate:string,sourceid:string,customerid:string,salestype:string,servicepersonid:string,reference:string,notes:string,discount:string,amount:string,netamount:string,toolid:string,tax:string,taxamount:string,pickuppostal:string ,pickupaddress:string,dropoffpostal:string,dropoffaddress:string,dropoffdate:string,pickupdate:string,deliverydate:string,orderdetdata:Object): ServicesActionType => ({
    type: ServicesActionTypes.UPD_SERVICESORDER,
    payload: {id ,entrydate, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount,toolid,tax,taxamount,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress,dropoffdate,pickupdate,deliverydate, orderdetdata},
});




export const updOrderStages = (id:string,stageid:string,dropofffile:object,pickupfile:object,progress:string): ServicesActionType => ({
    type: ServicesActionTypes.UPD_ORDER_STAGE,
    payload: {id,stageid,dropofffile,pickupfile,progress},
});






export const addServices = (orderid:string,entrydate:string,name:string,phone:string,address:string,sourceid:string,customerid:string,salestype:string,servicepersonid:string,reference:string,notes:string,discount:string,amount:string,netamount:string,toolid:string,tax:string,taxamount:string,bankreceived:string,bankid:string,cashreceived:string,pickuppostal:string ,pickupaddress:string,dropoffpostal:string,dropoffaddress:string,phonecode:string ,cashcounterid:string,orderdetdata:Object): ServicesActionType => ({
    type: ServicesActionTypes.ADD_SERVICES,
    payload: { orderid,entrydate, name,
        phone,
        address,sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount,toolid,tax,taxamount,bankreceived,
        bankid,
        cashreceived,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress,phonecode,cashcounterid, orderdetdata},
});

export const updServices = (id:string,orderid:string,entrydate:string,name:string,phone:string,address:string,sourceid:string,customerid:string,salestype:string,servicepersonid:string,reference:string,notes:string,discount:string,amount:string,netamount:string,toolid:string,tax:string,taxamount:string,bankreceived:string,bankid:string,cashreceived:string,pickuppostal:string ,pickupaddress:string,dropoffpostal:string,dropoffaddress:string,phonecode:string,cashcounterid:string,orderdetdata:Object): ServicesActionType => ({
    type: ServicesActionTypes.UPD_SERVICES,
    payload: {id ,orderid,entrydate,name,
        phone,
        address, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount,toolid,tax,taxamount,bankreceived,
        bankid,
        cashreceived,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress,phonecode,cashcounterid,orderdetdata},
});

export const resetServices = (): ServicesActionType => ({
    type: ServicesActionTypes.RESET,
    payload: {},
});
